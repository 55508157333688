<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <div class="row">
      <div class="col">
        <BookView :book="bookAsArray" :busy="bookLoading">
          <template v-slot:controls>
            <button class="btn btn-sm btn-outline-success" @click="$router.push({name:'test-info', params:{book_id}})" title="Test yourself with questions from this book"><TestIcon /> Take a Test</button>
            <BookFavoriteButton class="ml-2" :book_id=book_id button />
          </template>
        </BookView>
      </div>
    </div>
  </div>
</template>

<script>
import TestIcon from 'mdi-vue/MapClockOutline';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import BookView from '@/views/public/book/components/BookView.vue';
import BookFavoriteButton from '@/views/public/book/components/BookFavoriteButton.vue';
import getBookMixin from '@/mixins/getbook';

export default {
  name: 'BookPreview',

  mixins: [getBookMixin],
  components: {
    BreadCrumb, BookView, BookFavoriteButton, TestIcon,
  },

  data() {
    return {
      book_id: this.$route.params.book_id,
      book: null,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books Categories', to: { name: 'book-cat-list' } },
        { text: (this.book ? this.book.title : this.book_id), active: true },
      ];
    },
  },

};
</script>

<style lang="stylus">

</style>
